import React from "react"
import PropTypes from "prop-types"
import { graphql, navigate } from "gatsby"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "@chakra-ui/react"

function embeddedAsset(node) {
  return (
    <img
      style={{
        maxWidth: "100%",
      }}
      src={node?.data?.target?.fields?.file["en-US"]?.url}
      alt=""
    />
  )
}

const Article = ({ data, location }) => {
  const article = data?.contentfulArticle
  if (!article) navigate("/")
  const docContent = article.content?.raw
    ? JSON.parse(article.content?.raw)
    : null
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => embeddedAsset(node),
    },
  }
  return (
    <Layout location={location} banner={article}>
      <SEO title={article.title} />
      <Container
        maxW="1000px"
        marginTop={["10", "10", "20", "20"]}
        className="onf-article-content"
      >
        <div>{documentToReactComponents(docContent, options)}</div>
      </Container>
    </Layout>
  )
}

Article.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
  location: PropTypes.object,
}

export default Article

export const pageQuery = graphql`
  query ContentfulArticleBySlug($title: String!) {
    contentfulArticle(title: { eq: $title }) {
      title
      content {
        raw
      }
      ingress
      headerlinks {
        links {
          link
          name
        }
      }
      backgroundImage {
        fluid {
          src
        }
      }
    }
  }
`
